import logo from './coming-soon.jpg';
import './App.css';

function App() {
  return (
      <img src={logo} style={{width: '100vw', height: '100svh', objectFit: 'contain'}} alt="logo" />
      
    
  );
}

export default App;
